.mpc-3-background-daytime-mode-202302-icon {
  width: 100vw;
  height: 134.688vw;
  object-fit: cover;
  position: absolute;
  top: 171.667vw;
  left: 0;
}

.mpc-3-advantage-of-iota {
  font-size: var(--mpc-3-font4-size);
  color: var(--mpc-3-dark-b1);
  text-align: left;
  width: 39.7917vw;
  height: 2.70833vw;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: absolute;
  top: 1.30208vw;
  left: 9.89583vw;
}

.mpc-3-frame-child, .mpc-3-frame-item {
  border-radius: var(--mpc-3-br-9xs);
  background-color: var(--mpc-3-light-t5);
  width: .625vw;
  height: 8.33333vw;
  position: relative;
}

.mpc-3-frame-item {
  background-color: var(--mpc-3-dark-tanglepay);
}

.mpc-3-rectangle-parent {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--mpc-3-gap-13xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 14.1667vw;
  left: 3.75vw;
}

.mpc-3-div1, .mpc-3-div2 {
  font-size: var(--mpc-3-font-size);
  color: var(--mpc-3-light-t1-day);
  text-align: right;
  width: 8.95833vw;
  height: 5vw;
  align-items: center;
  font-weight: 800;
  line-height: 7.5vw;
  display: flex;
  position: absolute;
  top: 33.125vw;
  left: 81.3542vw;
}

.mpc-3-div2 {
  text-align: left;
  top: 48.6458vw;
  left: 9.79167vw;
}

.mpc-3-community-driven-1, .mpc-3-rocket-scalable-1-icon {
  width: 10.7104vw;
  height: 14.5833vw;
  object-fit: cover;
  position: absolute;
  top: 28.3333vw;
  left: 67.4479vw;
}

.mpc-3-community-driven-1 {
  width: 20.2391vw;
  top: 43.8542vw;
  left: 20.2083vw;
}

.mpc-3-validators {
  font-size: var(--mpc-3-font4-size);
  color: var(--mpc-3-dark-b1);
  width: 15.8333vw;
  height: 2.70833vw;
  justify-content: center;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: absolute;
  top: 68.2813vw;
  left: 42.0833vw;
}

.mpc-3-space5-logo, .mpc-3-spyce-1-icon {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mpc-3-spyce-1-icon {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  bottom: 0;
  overflow: hidden;
}

.mpc-3-space5-logo {
  height: 69.49%;
  bottom: 30.51%;
}

.mpc-3-space5 {
  box-shadow: var(--mpc-3-validator);
  width: 8.54167vw;
  height: 12.2917vw;
  position: absolute;
  top: 79.5313vw;
  left: 38.75vw;
}

.mpc-3-soonaverse1 {
  height: 13.68%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 84.62%;
  left: 0;
}

.mpc-3-soon-token2x-2 {
  height: 112.5%;
  width: 112.5%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  inset: -6.5% -6% -6% -6.5%;
  overflow: hidden;
}

.mpc-3-soonaverse-logo {
  height: 70.09%;
  width: 72.57%;
  position: absolute;
  inset: 0 13.72% 29.91%;
}

.mpc-3-shimmer-gov, .mpc-3-soonaverse {
  width: 11.7708vw;
  height: 12.1875vw;
  position: absolute;
  top: 79.6354vw;
  left: 63.0208vw;
}

.mpc-3-shimmer-gov {
  height: 13.56%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  top: 84.75%;
  left: 0;
}

.mpc-3-shimmer-mark-dark2x-1 {
  height: 100%;
  width: 75.5%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0 12% 0 12.5%;
  overflow: hidden;
}

.mpc-3-shimmer-gov-logo {
  height: 69.49%;
  width: 72.57%;
  position: absolute;
  inset: 0 13.72% 30.51%;
}

.mpc-3-dltgreen, .mpc-3-shimmergov, .mpc-3-tangleswap-logo {
  width: 11.7708vw;
  height: 12.2917vw;
  position: absolute;
  top: 79.5313vw;
  left: 49.8958vw;
}

.mpc-3-dltgreen, .mpc-3-tangleswap-logo {
  width: 8.54167vw;
  top: 79.4271vw;
  left: 25vw;
}

.mpc-3-tangleswap-logo {
  height: 69.49%;
  width: 77.36%;
  box-shadow: var(--mpc-3-validator);
  inset: 0 11.32% 30.51%;
}

.mpc-3-tanglepay, .mpc-3-tangleswap {
  width: 11.0417vw;
  height: 12.2917vw;
  position: absolute;
  top: 79.6354vw;
  left: 10vw;
}

.mpc-3-tanglepay {
  height: 13.56%;
  width: 75.33%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  top: 84.75%;
  left: 12.33%;
}

.mpc-3-logo-icon {
  height: 86.44%;
  width: 68%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 1.66667vw;
  position: absolute;
  inset: -6.78% 14.67% 20.34% 17.33%;
  overflow: hidden;
}

.mpc-3-shimmersea {
  width: 15.625vw;
  height: 12.2917vw;
  position: absolute;
  top: 79.5313vw;
  left: 75.0521vw;
}

.mpc-3-learn-more-child {
  height: 100%;
  width: 100%;
  border: .20833vw solid var(--mpc-3-dark-tanglepay);
  box-sizing: border-box;
  border-radius: .83333vw;
  position: absolute;
  inset: 0;
}

.mpc-3-learn-more1 {
  height: 80%;
  width: 93.75%;
  justify-content: center;
  align-items: center;
  line-height: 2.08333vw;
  display: flex;
  position: absolute;
  top: 10%;
  left: 3.57%;
}

.mpc-3-learn-more {
  box-shadow: var(--mpc-3-effect1);
  width: 15.625vw;
  height: 3.125vw;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-dark-tanglepay);
  position: absolute;
  top: 72.7604vw;
  left: 42.0833vw;
}

.mpc-3-security1 {
  width: 17.2917vw;
  height: 2.08333vw;
  align-items: center;
  line-height: 2.5vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.mpc-3-span {
  color: var(--mpc-3-dark-t1);
}

.mpc-3-validators1 {
  color: var(--mpc-3-dark-tanglepay);
}

.mpc-3-the-nodes-of-container1 {
  line-break: anywhere;
  width: 100%;
}

.mpc-3-the-nodes-of-container {
  font-size: var(--mpc-3-font1-size);
  width: 44.0104vw;
  height: 6.04167vw;
  color: var(--mpc-3-light-t1-day);
  align-items: center;
  display: flex;
  position: absolute;
  top: 3.95833vw;
  left: 0;
}

.mpc-3-security-parent {
  width: 44.0104vw;
  height: 9.89583vw;
  position: relative;
}

.mpc-3-learn-more-item {
  height: 100%;
  width: 100%;
  background-color: var(--mpc-3-light-t1-day);
  border-radius: .41667vw;
  position: absolute;
  inset: 0;
}

.mpc-3-learn-more2 {
  box-shadow: var(--mpc-3-effect1);
  width: 11.6667vw;
  height: 2.08333vw;
  text-align: center;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-dark-t1);
  position: relative;
}

.mpc-3-frame-parent, .mpc-3-security {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.mpc-3-frame-parent {
  width: 44.0104vw;
  height: 13.3333vw;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-security {
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 47.0833vw;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
  position: absolute;
  top: 8.17708vw;
  left: 43.2292vw;
}

.mpc-3-any-builder-could, .mpc-3-scalable1 {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.mpc-3-scalable1 {
  width: 13.5417vw;
  height: 2.08333vw;
  line-height: 2.5vw;
}

.mpc-3-any-builder-could {
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-light-t1-day);
  width: 47.0313vw;
  height: 3.54167vw;
}

.mpc-3-scalable, .mpc-3-scalable-parent {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.mpc-3-scalable-parent {
  width: 47.0313vw;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-scalable {
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 51.1458vw;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
  position: absolute;
  top: 29.7917vw;
  left: 9.89583vw;
}

.mpc-3-community-driven1, .mpc-3-the-iota-mpc {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.mpc-3-community-driven1 {
  width: 27.3958vw;
  height: 2.08333vw;
  line-height: 2.5vw;
}

.mpc-3-the-iota-mpc {
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-light-t1-day);
  width: 41.25vw;
  height: 3.54167vw;
}

.mpc-3-community-driven-parent {
  width: 41.25vw;
  justify-content: center;
  align-items: flex-start;
  gap: var(--mpc-3-gap-13xl);
  flex-direction: column;
  display: flex;
}

.mpc-3-community-driven, .mpc-3-div3 {
  text-align: left;
  display: flex;
  position: absolute;
}

.mpc-3-community-driven {
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 45.2083vw;
  height: 11.4583vw;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 45.4167vw;
  left: 45.1042vw;
}

.mpc-3-div3 {
  font-size: var(--mpc-3-font-size);
  color: var(--mpc-3-light-t1-day);
  width: 8.95833vw;
  height: 5vw;
  align-items: center;
  font-weight: 800;
  line-height: 7.5vw;
  top: 13.9063vw;
  left: 9.89583vw;
}

.mpc-3-security-1-icon {
  width: 18.3172vw;
  height: 15.625vw;
  object-fit: cover;
  position: absolute;
  top: 9.11458vw;
  left: 21.7708vw;
}

.mpc-3-div {
  width: 100%;
  height: 92.7083vw;
  text-align: center;
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-color-black);
  font-family: var(--mpc-3-font);
  position: relative;
  overflow: hidden;
}

.mpc-3-div3 {
  width: 100%;
  height: 92.7083vw;
  text-align: left;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-color-black);
  font-family: var(--mpc-3-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.5fa3e0c4.css.map */
