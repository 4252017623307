.mpc-3-background-daytime-mode-202302-icon {
  position: absolute;
  top: 3296px;
  left: 0;
  width: 1920px;
  height: 2586px;
  object-fit: cover;
}

.mpc-3-advantage-of-iota {
  position: absolute;
  top: 25px;
  left: 190px;
  font-size: var(--mpc-3-font4-size);
  line-height: 56px;
  display: flex;
  color: var(--mpc-3-dark-b1);
  text-align: left;
  align-items: center;
  width: 764px;
  height: 52px;
}

.mpc-3-frame-child,
.mpc-3-frame-item {
  position: relative;
  border-radius: var(--mpc-3-br-9xs);
  background-color: var(--mpc-3-light-t5);
  width: 12px;
  height: 160px;
}

.mpc-3-frame-item {
  background-color: var(--mpc-3-dark-tanglepay);
}

.mpc-3-rectangle-parent {
  position: absolute;
  top: 272px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-div1,
.mpc-3-div2 {
  position: absolute;
  top: 636px;
  left: 1562px;
  font-size: var(--mpc-3-font-size);
  line-height: 144px;
  font-weight: 800;
  color: var(--mpc-3-light-t1-day);
  text-align: right;
  display: flex;
  align-items: center;
  width: 172px;
  height: 96px;
}

.mpc-3-div2 {
  top: 934px;
  left: 188px;
  text-align: left;
}

.mpc-3-community-driven-1,
.mpc-3-rocket-scalable-1-icon {
  position: absolute;
  top: 544px;
  left: 1295px;
  width: 205.64px;
  height: 280px;
  object-fit: cover;
}

.mpc-3-community-driven-1 {
  top: 842px;
  left: 388px;
  width: 388.59px;
}

.mpc-3-validators {
  position: absolute;
  top: 1311px;
  left: 808px;
  font-size: var(--mpc-3-font4-size);
  line-height: 56px;
  display: flex;
  color: var(--mpc-3-dark-b1);
  align-items: center;
  justify-content: center;
  width: 304px;
  height: 52px;
}

.mpc-3-space5-logo,
.mpc-3-spyce-1-icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.mpc-3-spyce-1-icon {
  height: 100%;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.mpc-3-space5-logo {
  height: 69.49%;
  bottom: 30.51%;
}

.mpc-3-space5 {
  position: absolute;
  top: 1527px;
  left: 744px;
  box-shadow: var(--mpc-3-validator);
  width: 164px;
  height: 236px;
}

.mpc-3-soonaverse1 {
  position: absolute;
  height: 13.68%;
  width: 100%;
  top: 84.62%;
  left: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpc-3-soon-token2x-2 {
  position: absolute;
  height: 112.5%;
  width: 112.5%;
  top: -6.5%;
  right: -6%;
  bottom: -6%;
  left: -6.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.mpc-3-soonaverse-logo {
  position: absolute;
  height: 70.09%;
  width: 72.57%;
  top: 0;
  right: 13.72%;
  bottom: 29.91%;
  left: 13.72%;
}

.mpc-3-shimmer-gov,
.mpc-3-soonaverse {
  position: absolute;
  top: 1529px;
  left: 1210px;
  width: 226px;
  height: 234px;
}

.mpc-3-shimmer-gov {
  height: 13.56%;
  width: 100%;
  top: 84.75%;
  left: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpc-3-shimmer-mark-dark2x-1 {
  position: absolute;
  height: 100%;
  width: 75.5%;
  top: 0;
  right: 12%;
  bottom: 0;
  left: 12.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.mpc-3-shimmer-gov-logo {
  position: absolute;
  height: 69.49%;
  width: 72.57%;
  top: 0;
  right: 13.72%;
  bottom: 30.51%;
  left: 13.72%;
}

.mpc-3-dltgreen,
.mpc-3-shimmergov,
.mpc-3-tangleswap-logo {
  position: absolute;
  top: 1527px;
  left: 958px;
  width: 226px;
  height: 236px;
}

.mpc-3-dltgreen,
.mpc-3-tangleswap-logo {
  top: 1525px;
  left: 480px;
  width: 164px;
}

.mpc-3-tangleswap-logo {
  height: 69.49%;
  width: 77.36%;
  top: 0;
  right: 11.32%;
  bottom: 30.51%;
  left: 11.32%;
  box-shadow: var(--mpc-3-validator);
}

.mpc-3-tanglepay,
.mpc-3-tangleswap {
  position: absolute;
  top: 1529px;
  left: 192px;
  width: 212px;
  height: 236px;
}

.mpc-3-tanglepay {
  height: 13.56%;
  width: 75.33%;
  top: 84.75%;
  left: 12.33%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpc-3-logo-icon {
  position: absolute;
  height: 86.44%;
  width: 68%;
  top: -6.78%;
  right: 14.67%;
  bottom: 20.34%;
  left: 17.33%;
  border-radius: 32px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.mpc-3-shimmersea {
  position: absolute;
  top: 1527px;
  left: 1441px;
  width: 300px;
  height: 236px;
}

.mpc-3-learn-more-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
  border: 4px solid var(--mpc-3-dark-tanglepay);
  box-sizing: border-box;
}

.mpc-3-learn-more1 {
  position: absolute;
  height: 80%;
  width: 93.75%;
  top: 10%;
  left: 3.57%;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpc-3-learn-more {
  position: absolute;
  top: 1397px;
  left: 808px;
  box-shadow: var(--mpc-3-effect1);
  width: 300px;
  height: 60px;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-dark-tanglepay);
}

.mpc-3-security1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 48px;
  display: flex;
  align-items: center;
  width: 332px;
  height: 40px;
}

.mpc-3-span {
  color: var(--mpc-3-dark-t1);
}

.mpc-3-validators1 {
  color: var(--mpc-3-dark-tanglepay);
}

.mpc-3-the-nodes-of-container1 {
  line-break: anywhere;
  width: 100%;
}

.mpc-3-the-nodes-of-container {
  position: absolute;
  top: 76px;
  left: 0;
  font-size: var(--mpc-3-font1-size);
  display: flex;
  align-items: center;
  width: 845px;
  height: 116px;
  color: var(--mpc-3-light-t1-day);
}

.mpc-3-security-parent {
  position: relative;
  width: 845px;
  height: 190px;
}

.mpc-3-learn-more-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background-color: var(--mpc-3-light-t1-day);
}

.mpc-3-learn-more2 {
  position: relative;
  box-shadow: var(--mpc-3-effect1);
  width: 224px;
  height: 40px;
  text-align: center;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-dark-t1);
}

.mpc-3-frame-parent,
.mpc-3-security {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mpc-3-frame-parent {
  width: 845px;
  height: 256px;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-security {
  position: absolute;
  top: 157px;
  left: 830px;
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 904px;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
}

.mpc-3-any-builder-could,
.mpc-3-scalable1 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.mpc-3-scalable1 {
  line-height: 48px;
  width: 260px;
  height: 40px;
}

.mpc-3-any-builder-could {
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-light-t1-day);
  width: 903px;
  height: 68px;
}

.mpc-3-scalable,
.mpc-3-scalable-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.mpc-3-scalable-parent {
  width: 903px;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-scalable {
  position: absolute;
  top: 572px;
  left: 190px;
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 982px;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
}

.mpc-3-community-driven1,
.mpc-3-the-iota-mpc {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.mpc-3-community-driven1 {
  line-height: 48px;
  width: 526px;
  height: 40px;
}

.mpc-3-the-iota-mpc {
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-light-t1-day);
  width: 792px;
  height: 68px;
}

.mpc-3-community-driven-parent {
  width: 792px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpc-3-gap-13xl);
}

.mpc-3-community-driven,
.mpc-3-div3 {
  position: absolute;
  text-align: left;
  display: flex;
}

.mpc-3-community-driven {
  top: 872px;
  left: 866px;
  border-radius: var(--mpc-3-br-9xl);
  background-color: var(--mpc-3-dark-t1);
  box-shadow: var(--mpc-3-effect);
  width: 868px;
  height: 220px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--mpc-3-padding-21xl);
  box-sizing: border-box;
  font-size: var(--mpc-3-font2-size);
  color: var(--mpc-3-dark-b1);
}

.mpc-3-div3 {
  top: 267px;
  left: 190px;
  font-size: var(--mpc-3-font-size);
  line-height: 144px;
  font-weight: 800;
  color: var(--mpc-3-light-t1-day);
  align-items: center;
  width: 172px;
  height: 96px;
}

.mpc-3-security-1-icon {
  position: absolute;
  top: 175px;
  left: 418px;
  width: 351.69px;
  height: 300px;
  object-fit: cover;
}

.mpc-3-div {
  position: relative;
  width: 100%;
  height: 1780px;
  overflow: hidden;
  text-align: center;
  font-size: var(--mpc-3-font1-size);
  color: var(--mpc-3-color-black);
  font-family: var(--mpc-3-font);
}



.mpc-3-div3 {
  position: relative;
  width: 100%;
  height: 1780px;
  overflow: hidden;
  text-align: left;
  font-size: var(--mpc-3-font3-size);
  color: var(--mpc-3-color-black);
  font-family: var(--mpc-3-font);
}